import styles from './Menu.module.css'

export default function Menu(props) {
  const handleNavClick = (e) => {
    const platform = parseInt(e.target.dataset.platform);
    props.changePlatform(platform)
  }

  return (
    <div className={styles.wrapper} data-menu-active={props.menuActive}>
      <nav>
        <div onClick={handleNavClick} data-platform='1' className={styles.navItem}>
          <div>INFINITE ICON</div>
        </div>
        <div onClick={handleNavClick} data-platform='2' className={styles.navItem}>
          <div>SHOP</div>
        </div>
        <div onClick={handleNavClick} data-platform='3' className={styles.navItem}>
          <div>CHASIN'</div>
        </div>
        <div onClick={handleNavClick} data-platform={'4'} className={styles.navItem}>
          <div>I'M FREE</div>
        </div>
        <div onClick={handleNavClick} data-platform={'5'} className={styles.navItem}>
          <div>BBA</div>
        </div>
      </nav>
    </div>
  )
}