import { useEffect, useState } from "react";
import Phase3 from "./components/Phase3/Phase3";


function App() {
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [phase, setPhase] = useState(5);

  const [loading, setLoading] = useState(true);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    const currentURL = window.location;
    const currentURLParams = new URLSearchParams(currentURL.search);
    const access_token = currentURLParams.get('access_token');
    if (access_token) {
      setPasswordProtected(false)
    }
  }, [])

  useEffect(() => {
    if (assetsLoaded) {
      setLoading(false);
    }
  }, [assetsLoaded])

  useEffect(() => {
    if (!passwordProtected) {
      const patchbayScript = document.createElement('script');
      patchbayScript.type = 'module';
      patchbayScript.onload = function () {
        //console.log('IM script')
      };
      patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=663d61c6c6b6ba600bef4cb2";
      document.head.appendChild(patchbayScript);

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);
    }
  }, [passwordProtected])

  const check = (value) => {
    if (value === 'B3Z5WE78') {
      setPasswordProtected(false)
    }
  }

  const handleAssetsLoaded = () => {
    setAssetsLoaded(true);
  }

  return <Phase3 loading={loading} handleAssetsLoaded={handleAssetsLoaded} />
}

export default App;
